import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as resources from "./translation.json";

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: (window.navigator.language === 'fr-FR' || 'fr') ? 'fr' : 'en', 
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;