import React from "react";

export default function Yoga() {
    return <main className="text-gray-400 bg-white body-font">
        <table>
            <thead>
                <tr>
                    <th>Carte 10 cours</th>
                    <th>Date de début</th>
                    <th>Présence</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Sylvie</td>
                    <td>17/09/2024</td>
                    <td>17/09/2024</td>
                </tr>
                <tr>
                    <td>Elodie</td>
                    <td>17/09/2024</td>
                    <td>17/09/2024</td>
                </tr>
            </tbody>
        </table>
    </main>
}