import React from "react";

export default function About() {
    return (
        <section id="about">
            <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
                <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                    <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
                        Salut, je suis Eddy.
                        <br className="hidden lg:inline-block" />J'aime enseigner la fabuleuse pratique du Yoga
                    </h1>
                    <p className="mb-8 leading-relaxed">
                        Depuis 2018, j'ai découvert le Yoga dans une salle de sport, et depuis il ne m'a jamais quitté. A la suite d'enseignements différents, j'ai décidé de partir en Inde apprendre de ce que je considère la source. Pour être sûr d'attirer les bonnes choses, j'ai fais Vipasssana, puis j'ai fais le tour de l'Europe et enfin je suis parti à Rishikesh.
                    </p>
                    <div className="flex justify-center">
                        <a
                            href="#contact"
                            className="inline-flex text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg">
                            Mes expériences
                        </a>
                        <a
                            href="#projects"
                            className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg">
                            Mes diplômes
                        </a>
                    </div>
                </div>
                <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                    <img
                        className="object-cover object-center rounded"
                        alt="hero"
                        src="./yoga.png"
                    />
                </div>
            </div>
        </section>
    );
}